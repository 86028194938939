<template>
    <div v-if="type === 'icons'">
      <div>
        <locked-field-v3 v-if="config" :value="config.lockedFields" class="d-inline" field="budgetBiasStrategy" @input="changeLockedFields($event)"/>
        <span>Pacing Strategy - {{ currentPacingStrategyLabel }}</span>
        <b-btn @click="showAll = !showAll" variant="link">
          <fluency-icon type="graph" />
          Show {{ showAll ? 'Fewer' : 'All' }} Pacing Strategies
        </b-btn>
      </div>

      <div class="d-flex flex-wrap w-100">
        <pacing-strategy-card-option v-for="(strategy) in primaryPacingStrategies"
                                     :key="strategy.value"
                                     :disabled="disabled"
                                     :pacing-strategy="strategy"
                                     :classes="[{ glow: strategy.value === updates.budgetBiasStrategy, 'glow-hover': !disabled, 'disabled cursor-disabled': disabled }]"
                                     :icons-per-row="iconsPerRow"
                                     @click="changeBudgetType($event)">
          <template #additionalInputs>
            <div v-if="strategy.value === 'FIXED_DAILY' && updates.budgetBiasStrategy === 'FIXED_DAILY'" class="text-left py-2 px-3">
              <div class="mad-lib-container">
                <basic-mad-lib-input class="py-2 px-3"
                                     :value="updates.fixedBudgetValue"
                                     :disabled="updates.budgetBiasStrategy !== 'FIXED_DAILY'"
                                     format="CURRENCY"
                                     @input="updates.fixedBudgetValue = $event; changeFixedBudgetValue()" />
              </div>
            </div>
            <div v-if="strategy.value === 'METRIC_TRACKER' && updates.budgetBiasStrategy === 'METRIC_TRACKER'" class="text-left py-2 px-3">
              <div class="mad-lib-container">
                <mad-lib-select :value="updates.paceToMetric"
                                class="pb-2"
                                placeholder="Select A Metric"
                                :disabled="updates.budgetBiasStrategy !== 'METRIC_TRACKER'"
                                :options="paceToMetricOptions"
                                :formatter="$filters.convertFromCapsUnderscore"
                                @input="updates.paceToMetric = $event; saveChanges()" />
              </div>

              <div v-if="proratedMetricAmount" class="my-3">
                <span class="text-muted font-italic">Prorated Metric Goal this month:</span> <span>{{$filters.number(proratedMetricAmount, 0, true)}}</span>
              </div>

              <div class="d-flex">
                <div :class="`w-${shouldShowLifetimeValue ? '50' : '100'}`">
                  <label v-if="shouldShowLifetimeValue" class="mb-0">Monthly:</label>
                  <div class="mad-lib-container">
                    <basic-mad-lib-input :value="updates.paceToMetricValue"
                                         :disabled="disableMonthlyInput || updates.budgetBiasStrategy !== 'METRIC_TRACKER'"
                                         placeholder="Enter A Goal"
                                         format="NUMBER"
                                         @input="updates.paceToMetricValue = $event; saveChanges()" />
                  </div>
                </div>
                <div v-if="shouldShowLifetimeValue" class="w-50">
                  <label class="mb-0">Lifetime:</label>
                  <div class="mad-lib-container">
                    <basic-mad-lib-input :value="updates.paceToMetricLifetimeValue"
                                         :disabled="updates.budgetBiasStrategy !== 'METRIC_TRACKER'"
                                         placeholder="Enter A Goal"
                                         format="NUMBER"
                                         @input="updates.paceToMetricLifetimeValue = $event; saveChanges()" />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </pacing-strategy-card-option>
      </div>

      <div v-if="showAll" title="More Pacing Strategies">
        <div class="d-flex flex-wrap">
          <pacing-strategy-card-option v-for="(strategy) in secondaryPacingStrategies"
                                       :key="strategy.value"
                                       :disabled="disabled"
                                       :pacing-strategy="strategy"
                                       :classes="[{ glow: strategy.value === updates.budgetBiasStrategy, 'glow-hover': !disabled, 'disabled cursor-disabled': disabled }]"
                                       @click="changeBudgetType($event)" >

              <template #additionalInputs>
                <div v-if="strategy.value === 'FIXED_DAILY' && updates.budgetBiasStrategy === 'FIXED_DAILY'" class="text-left py-2 px-3">
                  <div class="mad-lib-container">
                    <basic-mad-lib-input class="py-2 px-3"
                                         :value="updates.fixedBudgetValue"
                                         :disabled="updates.budgetBiasStrategy !== 'FIXED_DAILY'"
                                         format="CURRENCY"
                                         @input="updates.fixedBudgetValue = $event; changeFixedBudgetValue()" />
                  </div>
                </div>
                <div v-if="strategy.value === 'METRIC_TRACKER' && updates.budgetBiasStrategy === 'METRIC_TRACKER'" class="text-left py-2 px-3">
                  <div class="mad-lib-container">
                    <mad-lib-select :value="updates.paceToMetric"
                                    class="pb-2"
                                    placeholder="Select A Metric"
                                    :disabled="updates.budgetBiasStrategy !== 'METRIC_TRACKER'"
                                    :options="paceToMetricOptions"
                                    :formatter="$filters.convertFromCapsUnderscore"
                                    @input="updates.paceToMetric = $event; saveChanges()" />
                  </div>
                  <div v-if="showFlightedOptions" class="d-flex">
                    <div :class="`w-${shouldShowLifetimeValue ? '50' : '100'}`">
                      <label v-if="shouldShowLifetimeValue" class="mb-0">Monthly:</label>
                      <div class="mad-lib-container">
                        <basic-mad-lib-input :value="updates.paceToMetricValue"
                                             :disabled="disableMonthlyInput || updates.budgetBiasStrategy !== 'METRIC_TRACKER'"
                                             placeholder="Enter A Goal"
                                             format="NUMBER"
                                             @input="updates.paceToMetricValue = $event; saveChanges()" />
                      </div>
                    </div>
                    <div v-if="shouldShowLifetimeValue" class="w-50">
                      <label class="mb-0">Lifetime:</label>
                      <div class="mad-lib-container">
                        <basic-mad-lib-input :value="updates.paceToMetricLifetimeValue"
                                             :disabled="updates.budgetBiasStrategy !== 'METRIC_TRACKER'"
                                             placeholder="Enter A Goal"
                                             format="NUMBER"
                                             @input="updates.paceToMetricLifetimeValue = $event; saveChanges()" />
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </pacing-strategy-card-option>
        </div>
      </div>
    </div>
    <div v-else>
      <b-form-group label="Pacing Strategy">
        <template #label>
          <locked-field-v3 v-if="config" :value="config.lockedFields" class="d-inline" field="budgetBiasStrategy" @input="changeLockedFields($event)"/>
          <span>Pacing Strategy</span>
        </template>

        <b-form-select :options="pacingStrategies" v-model="updates.budgetBiasStrategy" @change="changeBudgetType($event)"/>
      </b-form-group>
      <b-form-group v-if="updates.budgetBiasStrategy === 'FIXED_DAILY'" :state="updates.fixedBudgetValue !== null && updates.fixedBudgetValue !== ''" invalid-feedback="Please enter a fixed daily amount.">
        <!--<label :class="{ 'text-muted': updates.budgetBiasStrategy !== 'FIXED_DAILY' }">Fixed Budget Value</label>-->
        <b-input-group size="md" prepend="$">
          <b-input v-model="updates.fixedBudgetValue"
                   min="0"
                   type="number"
                   :debounce="300"
                   :placeholder="(updates.budgetBiasStrategy === 'FIXED_DAILY') ? '0.00' : 'n/a'"
                   :disabled="updates.budgetBiasStrategy !== 'FIXED_DAILY'"
                   @update="changeFixedBudgetValue"></b-input>
          <b-input-group-append>
            <b-btn class="border pr-3" variant="light">
              <locked-field-v3 v-if="config" :value="config.lockedFields" field="fixedBudgetValue" @input="changeLockedFields($event)"/>
            </b-btn>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <div v-if="updates.budgetBiasStrategy === 'METRIC_TRACKER'" class="d-flex align-items-center mb-3">
        <span class="mr-2">Metric:</span>
        <mad-lib-select :value="updates.paceToMetric"
                        :options="paceToMetricOptions"
                        :formatter="$filters.convertFromCapsUnderscore"
                        @input="updates.paceToMetric = $event; saveChanges()" />
        <span class="mr-2 ml-5 py-3">Goal:</span>
        <basic-mad-lib-input :value="updates.paceToMetricValue"
                             format="NUMBER"
                             @input="updates.paceToMetricValue = $event; saveChanges()" />
      </div>
    </div>
</template>

<script>
import { cloneDeep as _cloneDeep } from 'lodash'
import LockedFieldV3 from '@/components/common/lockedFieldV3'
import BasicMadLibInput from 'core-ui/components/inputs/basicMadLibInput'
import MadLibSelect from 'core-ui/components/inputs/madLibSelect'
import { PacingStrategies } from '../../../assets/js/constants'
import PacingStrategyCardOption from '@/components/common/pacingStrategyCardOption'

export default {
  name: 'bulkPacingStrategyOptions',
  components: { MadLibSelect, BasicMadLibInput, LockedFieldV3, PacingStrategyCardOption },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: () => {}
    },
    type: {
      type: String,
      default: 'icons'
    },
    showExternalOption: {
      type: Boolean,
      default: false
    },
    showCustomOption: {
      type: Boolean,
      default: false
    },
    iconsPerRow: {
      type: Number,
      default: 6
    },
    showOnlyCurrentSelection: {
      type: String,
      default: null
    },
    hideSegments: {
      type: Boolean,
      default: false
    },
    isCreateFlow: {
      type: Boolean,
      default: false
    },
    startDate: {
      type: String,
      default: null
    },
    endDate: {
      type: String,
      default: null
    },
    proratedMetricAmount: {
      default: null
    },
    showFlightedOptions: {
      type: Boolean,
      default: true
    },
    budgetAmount: {
      type: Number,
      default: null
    },
    advertisingChannelId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      showAll: false,
      paceToMetricOptions: ['CLICKS', 'IMPRESSIONS', 'CONVERSIONS', 'VIDEO_VIEWS'],
      updates: {
        budgetBiasStrategy: this.config?.budgetBiasStrategy,
        fixedBudgetValue: this.config?.fixedBudgetValue,
        paceToMetricValue: this.config?.paceToMetricValue,
        paceToMetric: this.config?.paceToMetric,
        paceToMetricLifetimeValue: this.config?.paceToMetricLifetimeValue
      }
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    },
    userCapabilities () {
      return this.user?.capabilities || []
    },
    currentPacingStrategyLabel () {
      const currentValue = this.updates.budgetBiasStrategy
      return this.pacingStrategies.find((strategy) => strategy?.value === currentValue)?.text || 'None'
    },
    pacingStrategies () {
      const strategies = _cloneDeep(PacingStrategies)

      return strategies.filter((strategy) => {
        if (!this.showExternalOption && strategy.value === 'EXTERNAL') {
          return false
        }

        if (!this.showCustomOption && strategy.value === 'CUSTOM') {
          return false
        }

        // Remove fixed daily and metric tracker for select dropdowns, used on Bulk Budget Worksheet. API call does not support this yet.
        if (this.type !== 'icons' && (strategy.value === 'FIXED_DAILY' || strategy.value === 'METRIC_TRACKER')) {
          return false
        }

        // remove anything with "SEGMENTED" from Blueprint Editor
        if (this.hideSegments && strategy.value.indexOf('SEGMENT') > -1) {
          return false
        }

        if (strategy.validChannels && this.advertisingChannelId) {
          return strategy.validChannels.includes(this.advertisingChannelId)
        }

        if (strategy.requiredCapabilities) {
          return strategy.requiredCapabilities.every(requiredCap => this.userCapabilities[requiredCap] === true)
        }

        return true
      })
    },
    primaryPacingStrategies () {
      if (this.showOnlyCurrentSelection && this.showOnlyCurrentSelection !== 'NONE') {
        return this.pacingStrategies.filter((strategy) => strategy.value === this.showOnlyCurrentSelection && !!strategy.svg)
      }

      return this.pacingStrategies.filter((strategy) => strategy.primary && !!strategy.svg)
    },
    secondaryPacingStrategies () {
      if (this.showOnlyCurrentSelection && this.showOnlyCurrentSelection !== 'NONE') {
        return this.pacingStrategies.filter((strategy) => strategy.value !== this.showOnlyCurrentSelection && !!strategy.svg)
      }
      return this.pacingStrategies.filter((strategy) => !strategy.primary && !!strategy.svg)
    },
    shouldShowLifetimeValue () {
      return !!(this.startDate && this.endDate)
    },
    disableMonthlyInput () {
      return !!(this.shouldShowLifetimeValue && this.config?.paceToMetricLifetimeValue)
    },
    shouldShowProratedMetricGoal () {
      if (this.budgetPlan?.budgetOptimizerStatusDTO?.hasOwnProperty('proratedMetricGoal')) {
        return true
      }

      return false
    }
  },
  watch: {
    disabled (newVal) {
      if (newVal) {
        this.updates.budgetBiasStrategy = null
        this.updates.fixedBudgetValue = null
        this.updates.paceToMetric = null
        this.updates.paceToMetricValue = null
        this.updates.paceToMetricLifetimeValue = null
      }
    },
    config (val) {
      if (val) {
        const { budgetBiasStrategy, fixedBudgetValue, paceToMetric, paceToMetricValue, paceToMetricLifetimeValue } = val
        this.updates = { budgetBiasStrategy, fixedBudgetValue, paceToMetric, paceToMetricValue, paceToMetricLifetimeValue }
      }
    }
  },
  methods: {
    updateLockedFields (key, fields) {
      const lockedFields = new Set((fields || '').split(','))
      lockedFields.add(key)
      return [...lockedFields].join(',')
    },
    async changeBudgetType (e) {
      if (this.disabled) {
        return false
      }

      // SPECIAL HANDLING FOR SWITCHING TO 'NONE'
      if (e === 'NONE' && this.budgetAmount) {
        const budgetAmount = this.budgetAmount
        const newAmount = budgetAmount * (12 / 365)
        const maths = `(${this.$filters.currency(budgetAmount, 2)} * 12 Months / 365 Days)`
        const conf = await this.$confirm({
          title: 'What You Should Know About Your Pacing Selection',
          text: `You're switching to a strategy that is primarily "hands off", however, when you make this adjustment, Fluency will perform a one-time setting to your budget to return it to the configured state.  That means that making this change is highly likely to perform a change of budget at the ad networks from this action, but will not track spend going forward.\n\nThe amount that is configured monthly is <b>${this.$filters.currency(budgetAmount, 2)}</b>. This setting will ensure the ad networks are budgeted at <b>${this.$filters.currency(newAmount, 2)}</b> per day <i>${maths}</i>.\n\nThis is a valid setting, but this notice is intended to ensure you're aware of the potential for this one-time change.`,
          'ok-title': 'I Understand'
        })

        if (!conf) {
          return false
        }
      }
      this.updates.budgetBiasStrategy = e
      this.updates.lockedFields = this.updateLockedFields('budgetBiasStrategy', this.config?.lockedFields)

      if (e !== 'FIXED_DAILY' || (e === 'FIXED_DAILY' && this.updates.budgetBiasStrategy !== 'FIXED_DAILY')) {
        this.updates.fixedBudgetValue = null
      }
      if (e !== 'METRIC_TRACKER' || (e === 'METRIC_TRACKER' && this.updates.budgetBiasStrategy !== 'METRIC_TRACKER')) {
        this.updates.paceToMetricValue = null
        this.updates.paceToMetric = null
        this.updates.paceToMetricLifetimeValue = null
      }

      this.saveChanges()
    },
    changeFixedBudgetValue () {
      this.updates.lockedFields = this.updateLockedFields('fixedBudgetValue', this.config?.lockedFields)
      this.saveChanges()
    },
    saveChanges () {
      this.$emit('changed', this.updates)
    },
    changeLockedFields (e) {
      this.updates.lockedFields = e
      this.saveChanges()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
