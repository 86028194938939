const PacingStrategies = [
  {
    value: 'EVEN',
    text: 'Even',
    description: 'Tracks spend, evenly through the month',
    svg: 'even.svg',
    primary: true
  },
  {
    value: 'TRAFFIC_TRACKER',
    text: 'Traffic Tracker',
    description: 'Track user behavior and adapt to traffic patterns.',
    svg: 'traffic-tracker.svg',
    primary: true
  },
  {
    value: 'STRONG_START',
    text: 'Strong Start',
    description: 'Spends more early and less later',
    svg: 'strong-start.svg',
    primary: true
  },
  {
    value: 'STRONG_FINISH',
    text: 'Strong Finish',
    description: 'Slows early spend and accelerates later',
    svg: 'strong-finish.svg',
    primary: true
  },
  {
    value: 'NONE',
    text: 'No Daily Pacing',
    description: 'This pacing strategy does not make daily adjustments.',
    svg: 'none.svg'
  },
  {
    value: 'WEEKDAYS',
    text: 'Weekdays',
    description: 'Focuses spend Mon - Fri',
    svg: 'weekdays.svg',
    primary: true
  },
  {
    value: 'WEEKENDS',
    text: 'Weekends',
    description: 'Focuses spend Sat - Sun',
    svg: 'weekends.svg'
  },
  {
    value: 'STANDARD_BUSINESS_WEEK',
    text: 'Front Loaded Business Week',
    description: 'Focuses spend at the start of a business week',
    svg: 'standard-business-week.svg'
  },
  {
    value: 'RECURRING_LIFETIME',
    text: 'Recurring Lifetime',
    description: 'Duplicates Facebook Lifetime Campaigns that have expired from last month',
    svg: 'recurring-lifetime.svg',
    validChannels: [2]
  },
  {
    value: 'EXTERNAL',
    text: 'External',
    description: 'Externally Managed Pacing Strategy',
    svg: 'external.svg',
    requiredCapabilities: ['ExternalBudgets']
  },
  {
    value: 'RECURRING_SEGMENTS',
    text: 'Segmented (Recurring)',
    description: 'Start and stop your spend periodically',
    svg: 'segmented.svg'
  },
  {
    value: 'SEGMENTS',
    text: 'Segmented (One-Time)',
    description: 'Start and stop your spend periodically',
    svg: 'segmented.svg'
  },
  /* DEPRECATED IN FAVOR OF SETTING A `readOnly` FLAG ON THE BUDGET PLAN OBJECT */
  // {
  //   value: 'READ_ONLY',
  //   text: 'Read Only',
  //   description: 'Fluency does not modify/manage budget',
  //   svg: 'read-only.svg'
  // },
  {
    value: 'CUSTOM',
    text: 'Custom',
    description: 'Define your own profile',
    svg: 'custom.svg'
  },
  {
    value: 'METRIC_TRACKER',
    text: 'Metric Based',
    description: 'Pace to user engagement',
    svg: 'metric-tracker.svg',
    primary: true
  },
  {
    value: 'FIXED_DAILY',
    text: 'Fixed Daily',
    description: 'Fixed User defined daily spend',
    svg: 'fixed-daily.svg'
  }
]

export default PacingStrategies
