<template>
  <div class="p-3" :style="styles">
    <div class="p-3 border text-center cursor-pointer h-100 d-flex flex-column"
         :class="classes"
         @click="$emit('click', pacingStrategy.value)">
      <h5 class="m-0">
        {{ pacingStrategy.text }}
        <!--<info-tooltip :title="pacingStrategy.description" />-->
      </h5>

      <div class="position-relative">
        <img :src="getImage(pacingStrategy.svg)"
             :alt="pacingStrategy.text"
             class="pacing-graph-img img-fluid" />
      </div>

      <slot name="additionalInputs" />

      <div class="small mt-2">{{pacingStrategy.description}}</div>
    </div>
  </div>
</template>

<script setup>
const glob = import.meta.glob('@/assets/svg/graphs/*.svg', { eager: true })
const images = Object.fromEntries(Object.entries(glob).map(([key, value]) => [key, value.default]))
const getImage = (img) => {
  return images[`/assets/svg/graphs/${img}`]
}
</script>
<script>
import InfoTooltip from '@/components/common/forms/infoTooltip'

export default {
  name: 'PacingStrategyCardOption',
  components: { InfoTooltip },
  props: {
    pacingStrategy: {
      type: Object,
      required: true
    },
    classes: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    iconsPerRow: {
      type: Number,
      default: 6
    }
  },
  data () {
    return {
    }
  },
  computed: {
    styles () {
      return {
        flex: `0 0 calc(100% / ${this.iconsPerRow})`,
        'min-width': '168px'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pacing-graph-img {
  max-height: 100px;
}
</style>
